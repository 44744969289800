function init(){
	const fileInputs = Array.prototype.slice.call(document.querySelectorAll('.js-file-input'));

	fileInputs.forEach(initFileInput);
}

function initFileInput(el){
	const input = el.querySelector('input[type=file]');
	const label = input.nextElementSibling;
	const chosenFile = label.nextElementSibling;

	var updateFileInput = function(e){
		let fileName = '';
		if(e.target.files.length > 1){
			let fileNameList = [];
			for (var i = 0; i < e.target.files.length; i++) {
				fileNameList.push(e.target.files[i].name);
			}

			fileName = fileNameList.join(', ');
		}else{
			if(e.target.files){
				fileName = e.target.value.split('\\').pop();
			}
		}

		if(fileName){
			chosenFile.innerHTML = fileName;
		}else{
			chosenFile.innerHTML = '';
		}
	};

	input.addEventListener('change', updateFileInput);
	updateFileInput({target: input});
}

export default init;
