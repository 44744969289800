import poly from "./util/polyfills";

import createMlMenu from "./ui/multi-level-menu";
import svg4everybody from "../vendor/svg4everybody";
import $ from "./../vendor/jquery";
import slick from "./../vendor/rdslick";
import validation from "./form/validation";
import fileInput from './form/file';
import flatpickr from './../vendor/flatpickr';

let basePath;

function init(){
	$.fn.rdslick = slick($);

	basePath = "/resources";
	if(typeof baseUrl != "undefined"){
		basePath = baseUrl;
	}

	poly();
	setupMenu();
	svg4everybody();
	setupSlider();
	setupDatePicker();
	contractorForm();

	validation('.js-form-validation');
	fileInput();
	accordions();
}

function setupMenu(){
	const menu = createMlMenu('.js-primary-nav', {
		side: 'right',
		clone: false,
		breadcrumbSpacer: '<div class="ml-menu__breadcrumb-space"><svg><use xlink:href="'+basePath+'/imgs/svgicons.svg#breadcrumb-spacer" /></svg></div>',
		subnavLinkHtml: '<svg><use xlink:href="'+basePath+'/imgs/svgicons.svg#menu-dots" /></svg>',
		backButtonHtml: '<svg><use xlink:href="'+basePath+'/imgs/svgicons.svg#menu-back" /></svg>',
		closeButtonHtml: '<svg><use xlink:href="'+basePath+'/imgs/svgicons.svg#close" /></svg>'
	});

	if(!menu.slideInController){
		return;
	}

	menu.slideInController.el.classList.remove('th-nav');

	const showMenu = document.querySelector('.js-menu-show');

	if(showMenu){
		showMenu.addEventListener('click', menu.slideInController.show);
	}
}

function setupSlider(){
	const slider = $(".js-quote-slider");
	slider.rdslick({
		cssEase: 'cubic-bezier(0.3, 0.15, 0.18, 1)',
		fade: true,
		arrows: false,
		dots: true,
		autoplay: true,
		autoplaySpeed: 3000,
		draggable: false
	});
}

function accordions(){
	$(document).on('click', '.js-accordion-btn', handleAccordion);
}

function handleAccordion(e){
	e.preventDefault();
	const accordion = $(e.target).closest('.js-accordion'),
		accordionBtn = accordion.find('.js-accordion-btn');
	if(accordion.hasClass('active')){
		if(accordion.hasClass('js-accordion-list-item')){
			return;
		}
		accordionBtn.removeClass('active');
		accordion.find('.js-accordion-content').slideUp({
			always: function(){
				accordion.removeClass('active');
			}
		});
	}else{
		if(accordion.hasClass('js-accordion-list-item')){
			var allAccordions = accordion.closest('.js-accordion-list').find('.js-accordion');
			allAccordions.find('.js-accordion-btn').removeClass('active');
			allAccordions.find('.js-accordion-content').slideUp({
				always: function(){
					$(this).closest('.js-accordion').removeClass('active');
				}
			});
		}
		accordionBtn.addClass('active');
		accordion.find('.js-accordion-content').slideDown({
			always: function(){
				accordion.addClass('active');
			}
		});
	}
}

function setupDatePicker(){
	flatpickr('.date-picker', {dateFormat: 'd/m/Y'});
}

function contractorForm(){
	$(document).on('click', '#compHasAltAddress', function(){
		if(this.checked){
			$('#altAddress').show();
		}else{
			$('#altAddress').hide();
		}
	});
}

document.addEventListener('DOMContentLoaded', init);
